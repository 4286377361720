<template>
  <section class="main-section lnb-tree-section">
    <main-section-header
      title="체크시트"
      :is-sticky="true"
      :is-smaller="true"
      :selected-item-name="selectedEqpNameCode"
    >
      <button-basic slot="button" color="gray-border-1" size="s" @click="onCancelSaveCheckSheet">취소</button-basic>
      <button-basic slot="button" color="dark" size="s" :disabled="!isFilledAllRequiredField" @click="onSaveCheckSheet">
        저장
      </button-basic>
    </main-section-header>
    <div class="container">
      <list-utility-menu>
        <button-basic slot="button" color="blue" size="s" @click="createCheckSheetItem" :width="113" :height="32">
          행 추가
        </button-basic>
      </list-utility-menu>
      <check-sheet-items
        :is-draggable="true"
        :is-possible-hover-list="false"
        :check-sheet-items="checkSheetItems"
        :is-edit-mode="true"
        @changedList="onChangedList"
        @copy="onCopyCheckSheetItem"
        @delete="onDeleteCheckSheetItem"
        @selectFile="onSelectFile"
        @deletePhoto="onDeletePhoto"
      />
    </div>
  </section>
</template>

<script>
import { mapState, mapActions } from 'vuex';
import MainSectionHeader from '@/component/mainSectionHeader/MainSectionHeader';
import CheckSheetItems from '@/view/checkSheet/CheckSheetItems';
import createUuid from '@/asset/js/createUuid';
import fileUpload from '@/asset/js/fileUpload';
import ListUtilityMenu from '@/component/list/ListUtilityMenu';
import isEmptyObject from '@/asset/js/isEmptyObject';
import eqpNameCodeByGroup from '@/asset/js/eqpNameCodeByGroup';

export default {
  name: 'CheckSheetRegister',
  props: [],
  data() {
    return {
      imageUrl: '',
      allFilename: '',
      fileBase64: '',
      checkSheetItems: [],
      checkSheetId: this.$route.params.cId,
      selectedEqpId: null,
      selectedCheckSheetItem: null,
    };
  },
  computed: {
    ...mapState('factoryEquipments', ['selectedEqpByGroup']),
    selectedEqpNameCode() {
      return eqpNameCodeByGroup(this);
    },
    checkSheetItemsReqBody() {
      return this.checkSheetItems.map((item, index) => {
        const checkSheetItemReqBody = {
          ...item,
          chkMethodDesc: item.chkMethodDesc.trim(),
          viewOrder: index + 1,
        };

        if ((!item.rscFileIds || item.rscFileIds.length === 0) && item.rscFiles.length > 0) {
          checkSheetItemReqBody.rscFileIds = [{ id: item.rscFiles[0].id }];
        }

        delete checkSheetItemReqBody.rscFiles;
        delete checkSheetItemReqBody.uuid;

        return checkSheetItemReqBody;
      });
    },
    isFilledAllRequiredField() {
      let isFilledAll = true;
      this.checkSheetItems.forEach((item) => {
        if (!item.name || !item.chkMethodDesc) {
          isFilledAll = false;
        }
      });
      return isFilledAll;
    },
  },
  created() {
    if (this.checkSheetId) {
      this.getCheckSheetItems(this.checkSheetId);
    } else {
      for (let i = 0; i < 3; i++) {
        this.createCheckSheetItem();
      }
    }
  },
  mounted() {},
  watch: {
    fileBase64() {
      if (this.fileBase64) {
        this.postCheckSheetItemFile({
          name: this.allFilename,
          bs64Strfile: this.fileBase64,
          chkGroupId: this.$route.query.eqp,
          type: 'img',
        });
      }
    },
  },
  methods: {
    ...mapActions('checkSheets', ['POST_CHECK_SHEET', 'DELETE_CHECK_SHEET']),
    ...mapActions('checkSheetItems', ['GET_CHECK_SHEET_ITEMS', 'POST_CHECK_SHEET_ITEMS']),
    ...mapActions('checkSheetItemFiles', ['POST_CHECK_SHEET_ITEM_FILE']),
    isEmptyObject(obj) {
      return isEmptyObject(obj);
    },
    createCheckSheetItem() {
      const newCheckSheetItem = this.getEmptyCheckSheetItem();
      this.checkSheetItems.push(newCheckSheetItem);
    },
    getEmptyCheckSheetItem() {
      return {
        id: null,
        uuid: createUuid(),
        chkCycle: 'Daily',
        name: '',
        chkMethodDesc: '',
        rscFiles: [],
        rscFileIds: [],
      };
    },
    onChangedList(changedList) {
      this.checkSheetItems = changedList;
    },
    openCheckSheetListPage() {
      this.$router.push(`/check-sheet?eqp=${this.$route.query.eqp}`).catch((err) => err);
    },
    onCancelSaveCheckSheet() {
      this.openCheckSheetListPage();
    },
    onCopyCheckSheetItem(checkSheetItem) {
      this.checkSheetItems.push({ ...checkSheetItem, id: null, uuid: createUuid() });
    },
    onDeleteCheckSheetItem(checkSheetItem) {
      this.checkSheetItems = this.checkSheetItems.filter((item) => {
        if (checkSheetItem.id) {
          return item.id !== checkSheetItem.id;
        } else {
          return item.uuid !== checkSheetItem.uuid;
        }
      });
    },
    onSelectFile(e, checkSheetItem) {
      fileUpload(this, e.target, false, true);
      this.selectedCheckSheetItem = checkSheetItem;
    },
    onDeletePhoto(checkSheetItem) {
      checkSheetItem.rscFileIds = [];
      checkSheetItem.rscFiles = [];
    },
    async postCheckSheetItemFile(file) {
      try {
        const fileId = await this.POST_CHECK_SHEET_ITEM_FILE(file);
        this.selectedCheckSheetItem.rscFileIds = [{ id: fileId }];
        this.selectedCheckSheetItem.rscFiles = [{ rscUrl: this.imageUrl }];
      } finally {
        this.allFilename = '';
        this.fileBase64 = '';
      }
    },
    async onSaveCheckSheet() {
      if (this.checkSheetId && this.checkSheetItems.length === 0) {
        await this.deleteCheckSheet(this.checkSheetId);
      } else {
        if (this.isFilledAllRequiredField) {
          !this.checkSheetId && (await this.postCheckSheet(this.$route.query.eqp));
          await this.postCheckSheetItems(this.checkSheetId, this.checkSheetItemsReqBody);
        }
      }
      this.openCheckSheetListPage();
    },
    async postCheckSheet(chkGroupId) {
      const checkSheet = await this.POST_CHECK_SHEET({
        chkGroupId: chkGroupId,
        name: this.selectedEqpByGroup.eqp['equipmentName'],
      });

      this.checkSheetId = checkSheet.id;
    },
    async postCheckSheetItems(chkSheetId, items) {
      return await this.POST_CHECK_SHEET_ITEMS({
        chkSheetId: chkSheetId,
        items: items,
      });
    },
    async deleteCheckSheet(sheetId) {
      await this.DELETE_CHECK_SHEET(sheetId);
    },
    async getCheckSheetItems(sheetId) {
      try {
        this.checkSheetItems = await this.GET_CHECK_SHEET_ITEMS(sheetId);
        this.checkSheetItems.sort(function (a, b) {
          return a.viewOrder - b.viewOrder;
        });
      } catch (error) {
        if (error.response.status === 404) {
          const path = window.location.hash.split('#/')[1];
          this.$router.replace(`/notfound?path=${path}`).catch((err) => err);
        }
      }
    },
  },
  components: { ListUtilityMenu, CheckSheetItems, MainSectionHeader },
};
</script>
