var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "section",
    { staticClass: "main-section lnb-tree-section" },
    [
      _c(
        "main-section-header",
        {
          attrs: {
            title: "체크시트",
            "is-sticky": true,
            "is-smaller": true,
            "selected-item-name": _vm.selectedEqpNameCode,
          },
        },
        [
          _c(
            "button-basic",
            {
              attrs: { slot: "button", color: "gray-border-1", size: "s" },
              on: { click: _vm.onCancelSaveCheckSheet },
              slot: "button",
            },
            [_vm._v("취소")]
          ),
          _c(
            "button-basic",
            {
              attrs: {
                slot: "button",
                color: "dark",
                size: "s",
                disabled: !_vm.isFilledAllRequiredField,
              },
              on: { click: _vm.onSaveCheckSheet },
              slot: "button",
            },
            [_vm._v(" 저장 ")]
          ),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "container" },
        [
          _c(
            "list-utility-menu",
            [
              _c(
                "button-basic",
                {
                  attrs: {
                    slot: "button",
                    color: "blue",
                    size: "s",
                    width: 113,
                    height: 32,
                  },
                  on: { click: _vm.createCheckSheetItem },
                  slot: "button",
                },
                [_vm._v(" 행 추가 ")]
              ),
            ],
            1
          ),
          _c("check-sheet-items", {
            attrs: {
              "is-draggable": true,
              "is-possible-hover-list": false,
              "check-sheet-items": _vm.checkSheetItems,
              "is-edit-mode": true,
            },
            on: {
              changedList: _vm.onChangedList,
              copy: _vm.onCopyCheckSheetItem,
              delete: _vm.onDeleteCheckSheetItem,
              selectFile: _vm.onSelectFile,
              deletePhoto: _vm.onDeletePhoto,
            },
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }